<template>
    <Loading v-if="!isLoaded" inside-container />
    <v-container v-else class="d-flex">
        <v-col>
            <toolbar v-if="billPayment.documentType === 'Credit'"
                :center-items="[
                    {
                        size: 'small',
                        color: 'error',
                        onClick: doDeleteBillPayment,
                        label: 'Delete Payment Line',
                        name: 'deletepayment',
                        confirm: 'Delete this Payment Line from this Payment Run?',
                    },
                ]"
            />
            <note-form
                v-if="billApproveId"
                collection-name="billApprove"
                :collection-id="billApproveId"
                @change="emitChange"
            />
        </v-col>
        <v-col>
            <pdf-preview
                v-for="fileObj in files"
                :fileObj="fileObj"
                :key="JSON.stringify(fileObj)"
                color="#00F"
                width="100%"
            />
        </v-col>
    </v-container>
</template>
<script>
import api from '@/api';
import PdfPreview from '@/components/common/PdfPreview';
import Loading from '@/components/app/Loading';
import NoteForm from '@/components/common/note/NoteForm';

export default {
    name: 'BillDetail',
    components: { Loading, PdfPreview, NoteForm },
    props: {
        billPaymentId: String,
        folders: Array,
        deleteBillPayment: Function,
    },
    data() {
        return {
            result: null,
            billPayment: null,
            billApproveId: null,
            isLoaded: false,
            files: [],
        };
    },
    methods: {
        async getDetail() {
            const result = await api.get(
                this.$store,
                `bill/paymentrun/payment/${this.billPaymentId}`,
            );

            if (result.data) {
                const { billPayment, attachables } = result.data;

                this.files = [...attachables];

                this.billPayment = billPayment;

                console.log('billPayment', this.billPayment)

                if (result.data.billApprove) {

                    this.billApproveId = result.data.billApprove.id;

                    console.log('billApproveId', this.billApproveId)

                    this.file = result.data.billApprove.billData.file;
                }
            }
        },
        async log(e) {
            console.log('logging', e);
        },
        emitChange(event) {
            console.log('emit change', event);
            this.$emit('change', event);
        },
        doDeleteBillPayment() {
            this.deleteBillPayment(this.billPayment.billPaymentRunId, this.billPayment.id);
        }
    },
    async created() {
        await this.getDetail();
        this.isLoaded = true;
    },
};
</script>
