<template>
    <Page :title="title" icon="mdi-cash-lock-open" class-extra="mx-auto">
        <template v-slot:header>
            <smart-form
                v-if="formData"
                :schema="formSchema"
                v-model="formData"
                key="filterForm"
                @input="updateFilterData"
                :hide-border="true"
                is-remove-padding
                class="col-10"
            />
        </template>
        <v-tabs
            v-if="billPaymentRunData && folders"
            :key="JSON.stringify(folders)"
            v-model="tabIndex"
            @change="checkTabChange"
        >
            <v-tab
                v-for="oneFolder in folders"
                :key="oneFolder.folderCode"
                :style="`background-color: #80808020; color: ${oneFolder.folderColor || 'red'};`"
                :value="oneFolder.folderCode"
                class="px-7"
            >
                {{ oneFolder.folderLabel }}
                <notification-badge :color="oneFolder.folderColor" :value="oneFolder.folderCount || '0'" />
            </v-tab>
            <v-tab-item v-for="oneFolder in folders" :key="oneFolder.folderCode" style="min-width: 1200px">
                <v-container class="col-12">
                    <v-row
                        v-for="billPaymentRunObj in billPaymentRunData[oneFolder.folderCode]"
                        :key="`${JSON.stringify(billPaymentRunObj)} ${JSON.stringify(formData)} ${folderCode}`"
                    >
                        <one-payment-run
                            :folder-code="oneFolder.folderCode"
                            :bill-payment-run="billPaymentRunObj.billPaymentRun"
                            :bill-payments="billPaymentRunObj.billPayments"
                            :credit-notes="creditNotes"
                            :action-payment-run="actionPaymentRun"
                            :delete-bill-payment="deleteBillPayment"
                            :load-detail="loadAPPaymentRunDetail"
                            :allocate-credit="allocateCredit"
                            :test-payment-run="testPaymentRun"
                            :key="`${JSON.stringify(billPaymentRunObj)} ${folderCode}`"
                        />
                    </v-row>
                </v-container>
            </v-tab-item>
        </v-tabs>
    </Page>
</template>

<script>
import api from '@/api';

import dateUtil from '@/../common/utils/date';

import OnePaymentRun from '@/views/accounts/bill/paymentRun/OnePaymentRun';

const formSchema = {
    upToReleaseDate: {
        component: 'date-field',
        label: 'Up To Release Date',
        cols: 4,
        on: {},
    },
    companyLink: {
        component: 'text-field',
        autocomplete: {
            type: 'company',
        },
        label: 'Company',
        cols: 3,
        clearable: true,
        returnObject: true,
        hideNoData: true,
    },
    vendorLink: {
        component: 'text-field',
        autocomplete: {
            type: 'vendor',
        },
        label: 'Vendor',
        cols: 3,
        clearable: true,
        returnObject: true,
        hideNoData: true,
    },
    isShowOther: {
        component: 'check-box-field',
        label: 'Show Other',
        cols: 2,
    },
};

const folderSetup = [
    { folderCode: 'released', folderLabel: 'New Released', folderColor: '#66c' },
    { folderCode: 'created', folderLabel: 'Created', folderColor: '#aa0' },
    { folderCode: 'authorised', folderLabel: 'Authorised', folderColor: '#6a4' },
    { folderCode: 'exported', folderLabel: 'Exported', folderColor: '#0a0' },
    { folderCode: 'cancelled', folderLabel: 'Cancelled', folderColor: '#a65' },
    {
        folderCode: 'other',
        folderLabel: 'Others (should not be any)',
        folderColor: '#f00',
    },
];

export default {
    name: 'APPaymentRun',
    components: { OnePaymentRun },
    data() {
        return {
            formData: null,
            formSchema,
            title: 'Payment Run',
            billPaymentRunData: null,
            billPaymentRunObjs: null,
            creditNotes: null,
            folderSetup,
            folderCode: this.$route.params.folderCode,
            folders: null,
            tabIndex: null,
            toolbarItems: null,
            generatedPaymentRuns: [],
        };
    },
    methods: {
        async checkTabChange(tabIndex) {
            const currentTab = this.folders[tabIndex];
            this.folderCode = currentTab.folderCode;
            this.$router.push(`/auth/bill/paymentrun/${currentTab.folderCode}`);

            if (currentTab.folderCode === 'released') {
                await this.loadAPGenerateNewPaymentRuns();
            }
        },
        sortBillPaymentRunsIntoFolders() {
            const filterBillPaymentObjs = (unfilteredBillPaymentRunObjs) =>
                unfilteredBillPaymentRunObjs && unfilteredBillPaymentRunObjs.length
                    ? unfilteredBillPaymentRunObjs.filter(
                          (oneBillPaymentRunObj) =>
                              (!this.formData.companyLink ||
                                  oneBillPaymentRunObj.billPaymentRun.companyLink.id ===
                                      this.formData.companyLink.id) &&
                              (!this.formData.vendorLink ||
                                  (oneBillPaymentRunObj.billPayments &&
                                      oneBillPaymentRunObj.billPayments.find(
                                          (oneBillPayment) =>
                                              oneBillPayment.vendorLink.id === this.formData.vendorLink.id,
                                      ))),
                      )
                    : [];

            const billPaymentRunObjs = filterBillPaymentObjs(this.billPaymentRunObjs);
            const generatedPaymentRuns = filterBillPaymentObjs(this.generatedPaymentRuns);

            const billPaymentRunData = billPaymentRunObjs.reduce(
                (accum, oneBillPaymentRunObj) => {
                    const sortedPaymentRuns = { ...accum };
                    if (oneBillPaymentRunObj.folderCode && sortedPaymentRuns[oneBillPaymentRunObj.folderCode]) {
                        sortedPaymentRuns[oneBillPaymentRunObj.folderCode].push(oneBillPaymentRunObj);
                    } else if (this.formData.isShowOther) {
                        sortedPaymentRuns.other.push(oneBillPaymentRunObj);
                    }
                    return sortedPaymentRuns;
                },
                {
                    created: [],
                    authorised: [],
                    exported: [],
                    cancelled: [],
                    other: [],
                },
            );

            this.billPaymentRunData = {
                ...billPaymentRunData,
                released: generatedPaymentRuns,
            };
            this.setFolders();
        },
        setFolders() {
            // OTHER IS A FOLDER THAT WILL ONLY ACT AS A CATCH ALL IN CASE SOMETHING SCREWS UP ON THE BACKEND
            this.folders = this.folderSetup
                .map((oneFolderSetup) => ({
                    ...oneFolderSetup,
                    folderCount: this.billPaymentRunData[oneFolderSetup.folderCode]
                        ? this.billPaymentRunData[oneFolderSetup.folderCode].length
                        : 0,
                }))
                .filter((oneFolder) => oneFolder.folderCode !== 'other' || oneFolder.folderCount > 0);
        },
        async loadAPPaymentRuns(tempForm) {
            if (tempForm) this.formData = tempForm;
            const result = await api.get(this.$store, 'bill/paymentrun');

            if (result.data) {
                this.billPaymentRunObjs = result.data;
            }
        },
        async loadAPCreditNotes() {
            const result = await api.get(this.$store, 'bill/paymentrun/credits');

            if (result.data) {
                this.creditNotes = result.data;
            }
        },
        async testPaymentRun(billPaymentRun) {
            if (billPaymentRun.id) {
                const testResult = await api.get(this.$store, `bill/paymentrun/test/${billPaymentRun.id}`);
                if (testResult.data) {
                    console.log('Test Payment Run in 2Easy Result', testResult);
                    const { errorMessage, success } = testResult.data;
                    return { success, errorMessage };
                }
            }
        },
        async loadAPPaymentRunDetail(billPaymentRunObj) {
            const { billPaymentRun, billPayments } = billPaymentRunObj;

            if (billPaymentRun.id && !billPayments) {
                const result = await api.get(this.$store, `bill/paymentrun/${billPaymentRun.id}`);
                if (result.data) {
                    const loadedBillPaymentRunObj = result.data;

                    if (loadedBillPaymentRunObj.errorMessage) {
                        await this.$store.dispatch('setError', loadedBillPaymentRunObj.errorMessage);
                    }
                    this.billPaymentRunObjs = this.billPaymentRunObjs.map((oneBillPaymentRunObj) => {
                        try {
                            if (oneBillPaymentRunObj.billPaymentRun.id === loadedBillPaymentRunObj.billPaymentRun.id) {
                                return {
                                    ...loadedBillPaymentRunObj,
                                    folderCode: oneBillPaymentRunObj.folderCode,
                                };
                            }
                            return oneBillPaymentRunObj;
                        } catch (e) {}
                    });

                    // this.testPaymentRun(billPaymentRun);

                    return loadedBillPaymentRunObj;
                }
            }
        },
        async loadAPGenerateNewPaymentRuns(newFormData) {
            if (newFormData) this.formData = { ...newFormData };

            const result = await api.post(this.$store, 'bill/paymentrun/generate', {
                upToReleaseDate: this.formData.upToReleaseDate,
            });

            if (result.data) {
                this.generatedPaymentRuns = result.data;
                this.sortBillPaymentRunsIntoFolders();
            }
        },
        async actionPaymentRun(
            action,
            billPaymentRun,
            billPayments,
            selectedBillPayments,
            settlementDate,
            isSuppressEmails,
        ) {
            action !== 'export' && (await this.$store.dispatch('setLoading', 'Loading Payment Run Info'));
            action === 'export' && this.$store.dispatch('setLoading', 'Exporting Payment Run');

            const filteredBillPayments = this.folderCode === 'released' ? selectedBillPayments : billPayments;

            const payload = {
                action,
                billPaymentRun,
                billPayments: filteredBillPayments,
                upToReleaseDate: this.formData.upToReleaseDate,
                settlementDate,
                isSuppressEmails,
            };

            console.log('******** running action paymentrun', action, {
                whole: JSON.stringify(payload).length,
                billPaymentRunLength: billPaymentRun ? JSON.stringify(billPaymentRun).length : undefined,
                billPaymentsLength: billPayments ? JSON.stringify(billPayments).length : undefined,
                billPaymentRun,
                billPayments,
            });

            const result = await api.post(this.$store, 'bill/paymentrun', payload);

            this.$store.dispatch('setLoading');

            console.log('@@@@ @@@@ action result', { ...result.data });

            if (result.data) {
                if (result.data.success) {
                    const { billPaymentRunId, billPaymentRunObj, folderCode } = result.data;

                    await this.loadAPGenerateNewPaymentRuns(this.formData);

                    if (folderCode && billPaymentRunId) {
                        const billPaymentRunObjs = this.billPaymentRunObjs.map((oneBillPaymentRunObj) => {
                            if (oneBillPaymentRunObj.billPaymentRun.id === billPaymentRunId) {
                                return {
                                    ...billPaymentRunObj,
                                    folderCode,
                                };
                            }
                            return oneBillPaymentRunObj;
                        });
                        this.billPaymentRunObjs = billPaymentRunObjs;
                        this.sortBillPaymentRunsIntoFolders();
                    } else {
                        await this.loadAPPaymentRuns();
                        this.sortBillPaymentRunsIntoFolders();
                    }
                } else {
                    this.$store.dispatch(
                        'setError',
                        `Error (${action}) Payment Run ${(billPaymentRun && billPaymentRun.id) || ''} - ${
                            result.data.errorMessage
                        }`,
                    );
                }
            }
        },
        async updateFilterData(formValue) {
            await this.loadAPGenerateNewPaymentRuns();
            this.sortBillPaymentRunsIntoFolders();
            return { formValue: this.formData };
        },
        async deleteBillPayment(billPaymentRunId, billPaymentId) {
            console.log('delete billPayment', { billPaymentId });
            this.$store.dispatch('setLoading', 'Deleting Line from Payment Run');

            const result = await api.post(this.$store, `bill/paymentrun/deletebillpayment`, {
                billPaymentId,
                upToReleaseDate: this.formData.upToReleaseDate,
            });

            if (result.data && result.data.errorMessage) {
                this.$store.dispatch('setError', result.data.errorMessage);
            }

            if (result.data) {
                await this.loadAPPaymentRunDetail({ billPaymentRun: { id: billPaymentRunId } });
                this.sortBillPaymentRunsIntoFolders();
            }
            this.$store.dispatch('setLoading');

            console.log('delete billPayment result', result.data);
        },
        async loadAll() {
            this.$store.dispatch('setLoading', 'Fetching Payment Runs From 2Easy');
            await Promise.all([this.loadAPPaymentRuns(), this.loadAPCreditNotes()]);
            this.$store.dispatch('setLoading');
            this.sortBillPaymentRunsIntoFolders();
        },
        allocateCredit(billPaymentRunId, creditNote) {
            return async () => {
                await this.$store.dispatch('setLoading', 'Allocating Credit to Payment Run');

                const result = await api.post(this.$store, `bill/paymentrun/creditallocation`, {
                    creditNote,
                    billPaymentRunId,
                });

                console.log('credit allocation result', result.data);
                if (result.data) {
                    const { billPaymentRunId, creditBillPayment, success, errorMessage } = result.data;

                    if (success) {
                        await this.loadAPPaymentRunDetail({
                            billPaymentRun: { id: billPaymentRunId },
                        });
                        this.sortBillPaymentRunsIntoFolders();
                    } else {
                        this.$store.dispatch('setError', errorMessage);
                    }
                }
                await this.$store.dispatch('setLoading');
            };
        },
    },
    async mounted() {
        const nextThursday = dateUtil.getNextWeekDayDate(null, 'Thursday');
        // there's another payment run on next friday so we want to sweep up all the payments due up to thursday.

        if (this.folderCode) {
            this.tabIndex = this.folderSetup.findIndex(
                (oneFolderSetup) => oneFolderSetup.folderCode === this.folderCode,
            );
        }

        this.formData = {
            upToReleaseDate: nextThursday,
            companyLink: null,
            vendorLink: null,
        };

        await this.loadAll();
        await this.updateFilterData(this.formData);
    },
};
</script>
