<template>
    <v-container class="col-12" v-if="this.billPaymentRunForm">
        <v-row class="rounded-border my-1">
            <v-col class="col-12">
                <smart-form v-model="billPaymentRunForm" :schema="paymentRunFormSchema" hide-border />
                <toolbar v-if="buttons && isTestComplete" :center-items="buttons">
                    <template v-slot:centerItems v-if="billPaymentRun">
                        <t-e-button-link
                            :company-id="billPaymentRun.companyLink.id"
                            :accounts-entity-name="billPaymentRun.accountsEntityName"
                            :accounts-id="billPaymentRun.accountsId"
                            size="small"
                        />
                    </template>
                </toolbar>
                <h3 v-if="testErrorMessage" class="error text-center">{{ testErrorMessage }}</h3>
            </v-col>
            <v-col class="col-12">
                <credit-notes
                    v-if="
                        ['created', 'authorised'].includes(folderCode) &&
                        billPaymentRunForm.paymentRunNumber &&
                        useBillPayments &&
                        useBillPayments.length
                    "
                    :bill-payment-run-id="billPaymentRun.id"
                    :credit-notes="creditNotes"
                    :bill-payments="useBillPayments"
                    :allocate-credit="allocateCredit"
                    :key="`credit${JSON.stringify(useBillPayments)}`"
                />
                <smart-table
                    v-if="headers && selected"
                    :items="useBillPayments"
                    :headers="headers"
                    group-by="payment.payee"
                    :key="JSON.stringify(useBillPayments)"
                    :show-select="folderCode === 'released'"
                    item-key="id"
                    v-model="selected"
                >
                    <template v-slot:group.header="{ items, isOpen, toggle }">
                        <th colspan="10">
                            <v-icon @click="toggle">
                                {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                            </v-icon>
                            {{ items[0].vendorLink.text }} - {{ items[0].payment.payee }}
                        </th>
                    </template>
                    <template v-slot:expanded-item="{ item }">
                        <td colspan="8">
                            <bill-detail
                                :billPaymentId="item.id"
                                @change="(event) => updatedNotes(item, event)"
                                :deleteBillPayment="deleteBillPayment"
                            />
                        </td>
                    </template>
                    <template v-slot:item.latestNote="{ item }">
                        <div class="d-flex align-center">
                            <fragment v-if="item.remittanceResult">
                                <p
                                    v-if="
                                        item.remittanceResult &&
                                        item.remittanceResult.emailServerResponse &&
                                        item.remittanceResult.emailServerResponse.errorMessage
                                    "
                                    class="error--text"
                                >
                                    {{ item.remittanceResult.emailServerResponse.errorMessage }}
                                </p>
                                <btn
                                    size="small"
                                    color="primary"
                                    icon-code="email"
                                    @click="() => (isShowEmailDetail = !isShowEmailDetail)"
                                />
                                <debug :value="item.remittanceResult" v-if="isShowEmailDetail" />
                            </fragment>
                            {{ item.noteText }}
                            <notification-badge
                                v-if="item.noteCount"
                                :value="item.noteCount && item.noteCount"
                                class="ml-2"
                            />
                        </div>
                    </template>
                </smart-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import BillDetail from '@/views/accounts/bill/paymentRun/BillDetail';
import CreditNotes from '@/views/accounts/bill/paymentRun/CreditNotes';
import TEButtonLink from '@/views/accounts/bill/common/TEButtonLink';

import Currency from '@/components/format/Currency';

import objectUtil from '@/../common/utils/object';

const paymentRunSchema = {
    companyCode: { component: 'text-field', readOnly: true, label: 'Company', cols: 6 },
    paymentRunNumber: { component: 'text-field', readOnly: true, label: 'Run #', cols: 3 },
    paymentType: { component: 'text-field', readOnly: true, label: 'Type', cols: 3 },
    bankCode: { component: 'text-field', readOnly: true, label: 'Bank', cols: 6 },
    paymentRunTotal: {
        component: 'currency-field',
        currency: '$',
        readOnly: true,
        label: 'Total',
        cols: 3,
    },
    paymentRunCount: { component: 'text-field', readOnly: true, label: '# Payment Lines', cols: 3 },
};

const authorisedSchema = {
    authorisedUser: { component: 'text-field', readOnly: true, label: 'Authorised By', cols: 4 },
    authorisedDate: { component: 'date-field', readOnly: true, label: 'Date', cols: 4 },
    settlementDate: { component: 'date-field', label: 'Settlement Date', today: true, cols: 4 },
    isSuppressEmails: { component: 'check-box-field', label: 'Suppress Emails to Vendors', today: true, cols: 12 },
};

const exportedSchema = {
    exportedUser: { component: 'text-field', readOnly: true, label: 'Exported By', cols: 4 },
    exportedDate: { component: 'date-field', readOnly: true, label: 'Date', cols: 4 },
    paymentRunDate: {
        component: 'date-field',
        readOnly: true,
        label: 'Payment Run Date',
        cols: 4,
    },
};

const billPaymentHeaders = [
    {
        text: 'Latest Note',
        align: 'left',
        sortable: true,
        value: 'latestNote',
    },
    {
        text: 'Release',
        align: 'left',
        sortable: true,
        value: 'releaseDate',
    },
    {
        text: 'Reference',
        align: 'left',
        sortable: true,
        value: 'documentReference',
    },
    {
        text: 'Document Total',
        align: 'right',
        sortable: true,
        value: 'documentTotal',
        customComponent: 'Currency',
    },
    {
        text: 'This Payment',
        align: 'right',
        sortable: true,
        value: 'paymentAmount',
        customComponent: 'Currency',
    },
    {
        text: 'Current Balance',
        align: 'right',
        sortable: true,
        value: 'documentBalance',
        customComponent: 'Currency',
    },
];

const referenceSchema = {
    text: 'Payment Reference',
    align: 'left',
    sortable: true,
    value: 'payment.paymentReference',
};

const billPaymentHeadersByType = {
    EFT: [
        {
            text: 'BSB',
            align: 'left',
            sortable: true,
            value: 'payment.eft.bsbNumber',
        },
        {
            text: 'Account',
            align: 'left',
            sortable: true,
            value: 'payment.eft.accountNumber',
        },
    ],
    BPAY: [
        {
            text: 'Biller Code',
            align: 'left',
            sortable: true,
            value: 'payment.bpayBillerCode',
        },
    ],
};

export default {
    components: { BillDetail, CreditNotes, TEButtonLink },
    props: {
        folderCode: String,
        billPaymentRun: Object,
        billPayments: Array,
        creditNotes: Array,
        actionPaymentRun: Function,
        deleteBillPayment: Function,
        loadDetail: Function,
        allocateCredit: Function,
        testPaymentRun: Function,
    },
    data() {
        return {
            selected: null,
            paymentRunSchema,
            authorisedSchema,
            exportedSchema,
            billPaymentHeaders,
            billPaymentHeadersByType,
            billPaymentRunForm: null,
            billPaymentsTable: null,
            useBillPayments: null,
            buttons: null,
            headers: null,
            isShowEmailDetail: false,
            select: null,
            isTestComplete: false,
            testErrorMessage: null,
        };
    },
    computed: {
        paymentRunFormSchema() {
            if (!this.billPaymentRun) return [];

            const authorisedSchema = this.authorisedSchema;
            authorisedSchema.settlementDate.readOnly = this.folderCode !== 'authorised';

            return {
                ...this.paymentRunSchema,
                ...(this.billPaymentRun.authorised ? authorisedSchema : {}),
                ...(this.billPaymentRun.exported ? this.exportedSchema : {}),
            };
        },
    },
    methods: {
        prepareBillPaymentRun() {
            this.billPaymentRunForm = {
                companyCode: this.billPaymentRun.companyLink.text,
                paymentRunNumber: this.billPaymentRun.paymentRunNumber,
                bankCode: this.billPaymentRun.bankLink.text,
                paymentType: this.billPaymentRun.paymentType,
                paymentRunTotal: this.billPaymentRun.paymentRunTotal,
                paymentRunCount: this.billPaymentRun.paymentRunCount.toString(),
                authorisedUser: objectUtil.getObjectFieldByString('authorised.userLink.text', this.billPaymentRun),
                authorisedDate: objectUtil.getObjectFieldByString('authorised.authorisedDate', this.billPaymentRun),
                exportedUser: objectUtil.getObjectFieldByString('exported.userLink.text', this.billPaymentRun),
                exportedDate: objectUtil.getObjectFieldByString('exported.exportDate', this.billPaymentRun),
                paymentRunDate: objectUtil.getObjectFieldByString('paymentRunDate', this.billPaymentRun),
                // default the settlement to payment run date if doesn't exist
                settlementDate: objectUtil.getObjectFieldByString('settlementDate', this.billPaymentRun),
            };
        },
        filterButtons() {
            const allButtons = [
                {
                    validFolderCodes: ['released'],
                    btn: { name: 'create', label: 'Create', preset: 'yes' },
                },
                {
                    validFolderCodes: ['created'],
                    btn: {
                        name: 'authorise',
                        label: 'Authorise',
                        preset: 'yes',
                        confirm: 'Authorise this Payment Run?',
                    },
                },
                {
                    validFolderCodes: ['created'],
                    btn: {
                        name: 'cancel',
                        label: 'Cancel',
                        preset: 'no',
                        confirm: 'Cancel this Payment Run?',
                    },
                },
                {
                    validFolderCodes: ['authorised'],
                    btn: {
                        name: 'export',
                        label: 'Export',
                        preset: 'yes',
                        confirm: 'Commit and Export this Payment Run?',
                    },
                },
                {
                    validFolderCodes: ['authorised'],
                    btn: {
                        name: 'unauthorise',
                        label: 'Un-Authorise',
                        preset: 'no',
                        confirm: 'Remove Authorisation for this Payment Run to be Exported?',
                    },
                },
                {
                    validFolderCodes: ['exported'],
                    btn: {
                        name: 'email',
                        label: 'Re-send Remittances',
                        size: 'small',
                        color: 'primary',
                        iconCode: 'email',
                        confirm: 'Re-send remittances?',
                    },
                },
            ];

            this.buttons = allButtons
                .filter((oneButton) => oneButton.validFolderCodes.includes(this.folderCode))
                .map((oneButton) => ({
                    ...oneButton.btn,
                    onClick: () =>
                        this.actionPaymentRun(
                            oneButton.btn.name,
                            this.billPaymentRun,
                            this.billPayments,
                            this.selected,
                            this.billPaymentRunForm.settlementDate,
                            this.billPaymentRunForm.isSuppressEmails,
                        ),
                }));
        },
        setTableHeaders() {
            const currentBillPayments = this.useBillPayments || [];
            const isReferenceNumbers = currentBillPayments.find(
                (oneBillPayment) => oneBillPayment.payment.paymentReference,
            );
            this.headers = [
                ...billPaymentHeaders,
                ...(isReferenceNumbers ? [referenceSchema] : []),
                ...(billPaymentHeadersByType[this.billPaymentRun.paymentType] || []),
            ];
        },
        updatedNotes(billPayment, event) {
            const currentBillPayments = this.useBillPayments || [];
            this.useBillPayments = currentBillPayments.map((oneBillPayment) => {
                if (billPayment.id === oneBillPayment.id) {
                    const updatedBillPayment = {
                        ...billPayment,
                        noteText: event.notes.length ? event.notes[event.notes.length - 1].noteText : '',
                        noteCount: event.notes.length,
                        paymentReference:
                            'Delete this Delete this Delete this Delete this Delete this Delete this Delete this Delete this ',
                    };
                    console.log('update bill payment', updatedBillPayment);
                    return updatedBillPayment;
                }
                return oneBillPayment;
            });
        },
        async init() {
            if (!this.billPayments) {
                const loadedBillPaymentRunObj = await this.loadDetail({
                    billPaymentRun: this.billPaymentRun,
                });
                this.useBillPayments = loadedBillPaymentRunObj.billPayments;
            } else {
                this.useBillPayments = [...this.billPayments];
            }
            this.prepareBillPaymentRun();
            this.filterButtons();
            this.selected = [...this.useBillPayments];
            this.setTableHeaders();
            if (this.billPaymentRun && this.billPaymentRun.id) {
                const { success, errorMessage } = await this.testPaymentRun(this.billPaymentRun);
                if (!success) this.testErrorMessage = errorMessage;
            }
            this.isTestComplete = true;
        },
    },
    async mounted() {
        console.log('onebillpayrun MOUNTED &&&&&&&&&&&&& ', this.folderCode, this.billPaymentRun);
        await this.init();
    },
};
</script>

<style scoped></style>
