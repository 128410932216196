<template>
    <div v-if="useCreditNotes && useCreditNotes.length && billPayments.length">
        <h3>Available Credits</h3>
        <smart-table
            :items="useCreditNotes"
            :headers="headers"
            :key="JSON.stringify(useCreditNotes)"
        >
            <template v-slot:expanded-item="{ item }">
                <td colspan="8">
                    detail goes here
                </td>
            </template>
            <template v-slot:item.action="{ item }">
                <toolbar
                    :center-items="[
                        {
                            size: 'small',
                            color: 'primary',
                            onClick: allocateCredit(billPaymentRunId, item),
                            label: 'Allocate Credit',
                            name: 'allocate',
                            confirm: 'Allocate Credit to this Payment Run?',
                            stopPrevent: true,
                        },
                    ]"
                />
            </template>
        </smart-table>
    </div>
</template>

<script>
import api from '@/api';

import Currency from '@/components/format/Currency';

const creditNoteHeaders = [
    {
        text: 'Vendor',
        align: 'left',
        sortable: true,
        value: 'vendorName',
    },
    {
        text: 'Reference',
        align: 'left',
        sortable: true,
        value: 'documentReference',
    },
    {
        text: 'Credit Balance',
        align: 'right',
        sortable: true,
        value: 'documentBalance',
        customComponent: 'Currency',
    },
    {
        text: 'Action',
        align: 'center',
        value: 'action',
    },
];

export default {
    data: () => ({
        useCreditNotes: null,
        buttons: null,
        headers: creditNoteHeaders,
    }),
    props: {
        billPaymentRunId: String,
        billPayments: Array,
        creditNotes: Array,
        allocateCredit: Function,
    },
    methods: {
        filterCreditNotesByPayments() {
            if (!this.creditNotes) {
                this.useCreditNotes = [];
            } else {
                console.log('creditNotes', this.creditNotes)
                const billPayments = this.billPayments || [];

                this.useCreditNotes = this.creditNotes.filter(
                    oneCreditNote =>
                        billPayments.find(
                            oneBillPayment =>
                                oneBillPayment.companyLink.id === oneCreditNote.companyLink.id &&
                                oneBillPayment.vendorLink.text === oneCreditNote.vendorLink.text,
                        ) &&
                        !billPayments.find(
                            oneBillPayment =>
                                oneBillPayment.companyLink.id === oneCreditNote.companyLink.id &&
                                oneBillPayment.accountsEntityName ===
                                    oneCreditNote.accountsEntityName &&
                                oneBillPayment.accountsId === oneCreditNote.accountsId,
                        ),
                );
            }
        },
    },
    async created() {
        this.creditNotes && console.log(
            'creditnotes mounted credit notes count',
            this.creditNotes.length,
            this.creditNotes[0],
        );
        this.filterCreditNotesByPayments();
    },
};
</script>

<style scoped></style>
