var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoaded)?_c('Loading',{attrs:{"inside-container":""}}):_c('v-container',{staticClass:"d-flex"},[_c('v-col',[(_vm.billPayment.documentType === 'Credit')?_c('toolbar',{attrs:{"center-items":[
                {
                    size: 'small',
                    color: 'error',
                    onClick: _vm.doDeleteBillPayment,
                    label: 'Delete Payment Line',
                    name: 'deletepayment',
                    confirm: 'Delete this Payment Line from this Payment Run?',
                },
            ]}}):_vm._e(),(_vm.billApproveId)?_c('note-form',{attrs:{"collection-name":"billApprove","collection-id":_vm.billApproveId},on:{"change":_vm.emitChange}}):_vm._e()],1),_c('v-col',_vm._l((_vm.files),function(fileObj){return _c('pdf-preview',{key:JSON.stringify(fileObj),attrs:{"fileObj":fileObj,"color":"#00F","width":"100%"}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }