var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.useCreditNotes && _vm.useCreditNotes.length && _vm.billPayments.length)?_c('div',[_c('h3',[_vm._v("Available Credits")]),_c('smart-table',{key:JSON.stringify(_vm.useCreditNotes),attrs:{"items":_vm.useCreditNotes,"headers":_vm.headers},scopedSlots:_vm._u([{key:"expanded-item",fn:function({ item }){return [_c('td',{attrs:{"colspan":"8"}},[_vm._v(" detail goes here ")])]}},{key:"item.action",fn:function({ item }){return [_c('toolbar',{attrs:{"center-items":[
                    {
                        size: 'small',
                        color: 'primary',
                        onClick: _vm.allocateCredit(_vm.billPaymentRunId, item),
                        label: 'Allocate Credit',
                        name: 'allocate',
                        confirm: 'Allocate Credit to this Payment Run?',
                        stopPrevent: true,
                    },
                ]}})]}}],null,false,209608853)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }