var render = function render(){var _vm=this,_c=_vm._self._c;return (this.billPaymentRunForm)?_c('v-container',{staticClass:"col-12"},[_c('v-row',{staticClass:"rounded-border my-1"},[_c('v-col',{staticClass:"col-12"},[_c('smart-form',{attrs:{"schema":_vm.paymentRunFormSchema,"hide-border":""},model:{value:(_vm.billPaymentRunForm),callback:function ($$v) {_vm.billPaymentRunForm=$$v},expression:"billPaymentRunForm"}}),(_vm.buttons && _vm.isTestComplete)?_c('toolbar',{attrs:{"center-items":_vm.buttons},scopedSlots:_vm._u([(_vm.billPaymentRun)?{key:"centerItems",fn:function(){return [_c('t-e-button-link',{attrs:{"company-id":_vm.billPaymentRun.companyLink.id,"accounts-entity-name":_vm.billPaymentRun.accountsEntityName,"accounts-id":_vm.billPaymentRun.accountsId,"size":"small"}})]},proxy:true}:null],null,true)}):_vm._e(),(_vm.testErrorMessage)?_c('h3',{staticClass:"error text-center"},[_vm._v(_vm._s(_vm.testErrorMessage))]):_vm._e()],1),_c('v-col',{staticClass:"col-12"},[(
                    ['created', 'authorised'].includes(_vm.folderCode) &&
                    _vm.billPaymentRunForm.paymentRunNumber &&
                    _vm.useBillPayments &&
                    _vm.useBillPayments.length
                )?_c('credit-notes',{key:`credit${JSON.stringify(_vm.useBillPayments)}`,attrs:{"bill-payment-run-id":_vm.billPaymentRun.id,"credit-notes":_vm.creditNotes,"bill-payments":_vm.useBillPayments,"allocate-credit":_vm.allocateCredit}}):_vm._e(),(_vm.headers && _vm.selected)?_c('smart-table',{key:JSON.stringify(_vm.useBillPayments),attrs:{"items":_vm.useBillPayments,"headers":_vm.headers,"group-by":"payment.payee","show-select":_vm.folderCode === 'released',"item-key":"id"},scopedSlots:_vm._u([{key:"group.header",fn:function({ items, isOpen, toggle }){return [_c('th',{attrs:{"colspan":"10"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_vm._v(" "+_vm._s(items[0].vendorLink.text)+" - "+_vm._s(items[0].payment.payee)+" ")],1)]}},{key:"expanded-item",fn:function({ item }){return [_c('td',{attrs:{"colspan":"8"}},[_c('bill-detail',{attrs:{"billPaymentId":item.id,"deleteBillPayment":_vm.deleteBillPayment},on:{"change":(event) => _vm.updatedNotes(item, event)}})],1)]}},{key:"item.latestNote",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center"},[(item.remittanceResult)?_c('fragment',[(
                                    item.remittanceResult &&
                                    item.remittanceResult.emailServerResponse &&
                                    item.remittanceResult.emailServerResponse.errorMessage
                                )?_c('p',{staticClass:"error--text"},[_vm._v(" "+_vm._s(item.remittanceResult.emailServerResponse.errorMessage)+" ")]):_vm._e(),_c('btn',{attrs:{"size":"small","color":"primary","icon-code":"email"},on:{"click":() => (_vm.isShowEmailDetail = !_vm.isShowEmailDetail)}}),(_vm.isShowEmailDetail)?_c('debug',{attrs:{"value":item.remittanceResult}}):_vm._e()],1):_vm._e(),_vm._v(" "+_vm._s(item.noteText)+" "),(item.noteCount)?_c('notification-badge',{staticClass:"ml-2",attrs:{"value":item.noteCount && item.noteCount}}):_vm._e()],1)]}}],null,false,2857539905),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }